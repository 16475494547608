<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio Dispositivo</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content> 
      <div id="container">
        <div>
          <div id="header">
            <h2>Dati generali:</h2>
          </div>
          <ion-grid style="border:1px solid grey">
            <ion-row class="info" :value="d._id_" v-for="d,i in device" :key="'sonda-'+i"> 
              <ion-col>Identificativo:</ion-col><ion-col>{{d._id_}}</ion-col>
            </ion-row>
            <ion-row class="info" :value="d.sourceId" v-for="d,i in device" :key="'sonda-'+i"> 
              <ion-col>Nome univoco:</ion-col><ion-col>{{d.SourceId}}</ion-col>
            </ion-row>
            <ion-row class="info" :value="d.SourceDescription" v-for="d,i in device" :key="'sonda-'+i"> 
              <ion-col>SourceDescription:</ion-col><ion-col>{{d.SourceDescription}}</ion-col>
            </ion-row>
            <ion-row class="info" :value="d.Status" v-for="d,i in device" :key="'sonda-'+i"> 
              <ion-col>Status:</ion-col> <ion-col>{{d.Status}}</ion-col> 
            </ion-row>
            <ion-row class="info" :value="d.Target" v-for="d,i in device" :key="'sonda-'+i"> 
              <ion-col>Target:</ion-col><ion-col>{{d.Target}}</ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid, 
    IonHeader,
    IonMenuButton, 
    IonPage,
    IonRow, 
    IonTitle, 
    IonToolbar,
    toastController,
    alertController,
  } from '@ionic/vue';
  import {pencilOutline} from 'ionicons/icons';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButtons,
      IonCol,
      IonContent,
      IonGrid,
      IonHeader,
      IonMenuButton,
      IonPage,
      IonRow,
      IonTitle,
      IonToolbar,
    },
    data : function(){
      return{
        device:{
          _id_:'',
          Timestamp:'',
          DeviceId:'',
          SourceId:'',
          SourceDescription:'',
          Status:'',
          Target:'',
        },
        devId:'',
        nuovadescr:'',
        pencilOutline:pencilOutline,
      }
    },
    methods:{
      getDeviceInfo(){
        this.axios.post(this.apiUrl + 'get-device-info', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          this.device=response.data.result.Output
          this.devId=response.data.result.Output
          this.strRisultati= JSON.stringify(this.device)
        })
      },
      async updateDescr(){
        if(this.nuovadescr!=''){
          this.axios.post(this.apiUrl + 'update-descrizione', {SourceId:this.$route.params.id,nuovadescr:this.nuovadescr}, this.headers)
            .then(async (response) => {
              if(response.data.status_code==200){
                const toast = await toastController
                .create({
                  color: 'success',
                  message: response.data.message,
                  duration: 2000
                })
                this.$router.push('/folder/GestioneMES');
                return toast.present();
              }
            })     
        }else{
          const toast = await toastController
          .create({
            color: 'danger',
            message: 'Nuova descrizione non inserita',
            duration: 2000
          })
          return toast.present();
        } 
      },
      leaveDevice(){
        this.axios.post(this.apiUrl + 'leave-device', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/GestioneMES');
            return toast.present();
          }
        }) 
      },
      async alertDelete() {
        const alert = await alertController
        .create({
          header: 'Attenzione:',
          message: 'Dissociare il device?',
          buttons: [
            {
              text:'Annulla', 
              role: 'cancel',
            },
            {
              text:'Conferma',
              handler: () => {
                this.leaveDevice();
              }
            }
          ],
        });
        return alert.present();
      },
  },
  ionViewWillEnter: function(){
    this.getDeviceInfo();
  }
}
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin:15px;
  }

  #header{
    background-color: rgb(56,128,255);
    color:white;
    height:30%;
  }

  ion-row.info{
    border-bottom:1px solid grey;
  }
</style>